<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 mx-auto">{{ jobTitle }}</h1>
      <a @click="toAddForm">
        <span
          class="
            btn
            me-3
            font-18 font-500
            btn-xs
            rounded-xs
            shadow-s
            bg-highlight
          "
        >
          <i class="fas fa-plus select-all font-14 rounded-sm color-white"></i>
          Tambah</span
        >
      </a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->
    <div class="page-content mt-4">
      <JobCompany
        v-for="job in listJob"
        :key="job.id"
        :job="job"
        @deleteJob="actionDelete"
        @shareJob="actionShare"
      />

      <EmptyState
        v-if="listJob.length === 0"
        :message1="emptyMsg"
        :message2="'yang ditambahkan'"
      />
    </div>
    <!-- End Page Content -->
    <!-- modals delete -->
    <div
      id="menu-delete-job"
      class="menu menu-box-modal rounded-m"
      data-menu-width="350"
      style="display: block; width: 350px"
    >
      <div class="menu-title mb-3">
        <i
          class="
            fa fa-question-circle
            scale-box
            float-start
            me-3
            ms-3
            fa-3x
            mt-1
            color-blue-dark
          "
        ></i>
        <p class="color-highlight">Hapus {{ jobTitle }}</p>
        <h1 class="font-20">Apakah anda yakin?</h1>
        <a href="#" @click="menuClose" class="close-menu"
          ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content mt-0">
        <div
          class="input-style no-borders no-icon validate-field mb-4"
          :class="{ 'input-style-active': payloadDelete.reason }"
        >
          <input
            type="text"
            class="form-control validate-name"
            id="form1g"
            v-model="payloadDelete.reason"
            placeholder="Alasan menghapus"
            required
          />
          <label for="form1g" style="background-color: transparent">Alasan menghapus</label>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <a
              @click="menuClose"
              href="#"
              class="
                btn
                close-menu
                btn-full btn-m
                bg-red-dark
                font-600
                rounded-s
              "
              >No, batal</a
            >
          </div>
          <div class="col-6">
            <a
              @click="deleteJob"
              href="#"
              class="
                btn
                close-menu
                btn-full btn-m
                bg-green-dark
                font-600
                rounded-s
              "
              >Yes, hapus!</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- End modals delete -->

    <!-- modals share -->
    <div
      id="menu-share-job"
      class="menu menu-box-modal rounded-m"
      data-menu-width="350"
      style="display: block; width: 350px"
    >
      <div class="menu-title mb-3">
        <i
          class="
            fa fa-share
            scale-box
            float-start
            me-3
            ms-3
            fa-3x
            mt-1
            color-blue-dark
          "
        ></i>
        <p class="color-highlight">Share</p>
        <h1 class="font-20">{{ jobTitle }}</h1>
        <a href="#" @click="menuClose" class="close-menu"
          ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content mt-0">
        <div class="d-flex justify-content-center mb-3">
          <a
            @click="copyWhatsApp"
            href="#"
            class="btn close-menu btn-full btn-m bg-highlight font-600 rounded-s"
          >
            WhatsApp
          </a>
        </div>
      </div>
    </div>
    <!-- End modals share -->

    <div
      id="emailVerifyNotif"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <p class="boxed-text-l color-white opacity-70">
Email anda belum terverifikasi<br />
Silahkan cek inbox email Anda untuk melakukan verifikasi !
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>

    <div
      id="toast-bookmark"
      class="toast toast-tiny toast-top bg-highlight"
      data-bs-delay="1000"
      data-bs-autohide="true"
    >
      <i class="fa fa-info me-3"></i>disimpan
    </div>

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";
import JobCompany from "@/components/JobCompany";
import useClipboard from "vue-clipboard3";
import { Toast } from "bootstrap";
export default {
  name: "CreateJob",
  components: {
    Header,
    Footer,
    JobCompany,
    EmptyState,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      jobTitle: "Lowongan ",
      listJob: [],
      apiUrl: "/api/v1/employer/job/vacancy/",
      isLoading: true,
      jobType: "lowongan",
      isVerified: this.$store.state.account.isVerified,
      isAuthenticated: this.$store.state.isAuthenticated,
      jobId: "",
      jobName: "",
      jobSlug: "",
      emptyMsg: "Belum ada lowongan",
      payloadDelete: {
        job_id: 0,
        status: 'close',
        reason: '',
      }
    };
  },
  mounted() {
    init_template();
    var job_type = this.$route.params.job_type;
    if (job_type === "project") {
      this.jobTitle = "Freelance";
      this.emptyMsg = "Belum ada project";
      this.apiUrl = "/api/v1/employer/job/project/";
      this.jobType = "project";
    }
    this.getJobList();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuOpen,
    menuClose,
    actionDelete(jobInfo) {
      this.jobId = jobInfo.id;
      this.jobName = jobInfo.name;
      this.menuOpen("menu-delete-job");
      this.payloadDelete = {
        ...this.payloadDelete,
        job_id: jobInfo.id
      }
    },
    actionShare(jobInfo) {
      this.jobId = jobInfo.id;
      this.jobName = jobInfo.name;
      this.jobSlug = jobInfo.slug;
      this.menuOpen("menu-share-job")
    },
    async copyWhatsApp() {
      let jobUrl = window.location.origin + '/job-seeker/job/' + this.jobSlug
      let textMessage = `Lihat lowongan ini
      ${this.jobName}
      ${jobUrl}`

      window.location.href = `whatsapp://send?text=${encodeURI(textMessage)}`
    },
    toAddForm() {
      if (!this.isVerified) {
        this.menuOpen("emailVerifyNotif");
      } else {
        this.$router.push({
          name: "FormJob",
          query: { jobType: this.jobType },
        });
      }
    },
    deleteJob() {
      axios
        .post("/job/change-status", this.payloadDelete, {
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          console.log(data);
          this.getJobList();
          this.jobId = "";
          this.menuClose();
          this.payloadDelete = {
            job_id: 0,
            status: 'close',
            reason: '',
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },
    getJobList() {
      axios
        .get(this.apiUrl)
        .then((response) => {
          this.listJob = response.data;
          console.log(this.listJob[0]);
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
