<template>
  <div class="card card-style">
    <div class="content">
      <!-- Job Passed -->
      <div v-if="job.job_passed" class="card-top">
        <a
          href="#"
          class="
            btn btn-xs
            bg-red-dark
            color-white
            mt-0
            float-start
            font-8
            rounded-xs
          "
          >{{ passedTitle }}</a
        >
      </div>
      <div v-if="job.job_passed" class="card-center">
        <!--<img
          class="mx-auto"
          src="/images/expired-stamp.png"
          alt=""
          style="width: 40%; height: 50%"
        />!-->
      </div>
      <!--      <div v-if="job.job_passed" class="card-bottom">
        <router-link
          :to="{
            name: 'UpdateJob',
            params: { id: job.id },
            query: { recreate: 'true' },
          }"
          class="
            btn btn-s
            bg-orange-light
            color-white
            float-end
            me-4
            mb-2
            rounded-xl
          "
          ><i class="far fa-edit"></i> Tambah Lagi
        </router-link>
      </div> -->

      <!-- End Job Passed -->
      <div class="d-flex">
        <div class="flex-grow-1">
          <h4 class="mb-2" :class="{ 'mt-4': job.job_passed }">
            {{ job.title }}
          </h4>
          <span v-if="job.category" class="badge bg-orange-dark mb-3 p-1 me-1">{{
              job.category.name
            }}</span>
          <span
            v-if="job.work_loc_city"
            class="badge bg-yellow-dark mb-3 p-1 me-1"
          >{{ job.work_loc_city.name }}</span
          >
          <h5 class="font-12 font-500 color-gray-dark">
            <i class="fas fa-eye color-highlight font-14 me-1"></i>
            <span class="color-grey-dark">{{ job.view }} dilihat</span>

            <router-link
              :to="{
            name: 'ApplyJobList',
            params: { job_id: job.id + job.unique_id },
          }"
              class="color-gray-dark ms-2"
            >
              <i class="far color-highlight fa-file font-14 me-1"></i>
              {{ job.applied }} Kandidat
            </router-link>
            <router-link
              v-if="job.job_passed"
              :to="{
            name: 'UpdateJob',
            params: { id: job.id },
            query: { recreate: 'true' },
          }"
              class="btn btn-s bg-orange-light color-white float-end rounded-sm"
            ><i class="far fa-edit"></i> Tambah Lagi
            </router-link>
          </h5>
        </div>
        <div class="d-flex flex-column gap-2">
          <router-link
            v-if="!job.job_passed"
            :to="{ name: 'UpdateJob', params: { id: job.id } }"
            class="btn btn-s bg-highlight color-white float-end rounded-sm"
          ><i class="far fa-edit me-1"></i>
            Ubah
          </router-link>
          <a
            @click="shareJob(job.id, job.title, job.slug)"
            class="btn btn-s bg-highlight color-white float-end rounded-sm"
          >
            <i class="fas fa-share me-1"></i> Share
          </a>
          <a
            @click="deleteJob(job.id, job.title)"
            class="btn btn-s bg-orange-light color-white float-end rounded-sm"
          >
            <i class="fas fa-trash-alt me-1"></i> Hapus
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobCompany",
  props: {
    job: Object,
  },
  emits: [
    "deleteJob",
    "shareJob",
  ],
  data() {
    return {
      passedTitle: "",
    };
  },
  mounted() {
    this.job.job_type == "VC"
      ? (this.passedTitle = "Lowongan Berakhir")
      : (this.passedTitle = "Freelance Berakhir");
  },
  methods: {
    deleteJob(id, name) {
      this.$emit("deleteJob", { id: id, name: name });
    },
    shareJob(id, name, slug) {
      this.$emit("shareJob", { id: id, name: name, slug: slug });
    },
  },
};
</script>

<style scoped>
.expired-stamp {
  /* background-image: url("/images/expired-stamp.png"); */
}
</style>
